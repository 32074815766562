import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import React from "react"
import { components } from "react-select"
import { navigate } from "gatsby"

import Arrow from "../../common/assets/image/elegant/keyboard_arrow_down.svg"
import Container from "../../components/UI/Container"
import Dropdown from "../../components/Select"
import TreatmentsSelectWrapper, {
  DropdownChecker,
  DropdownControl,
} from "./treatmentsSelect.style"

const TreatmentsSelect = ({ sectionOptions, category, treatmentsData }) => {
  const options = data => {
    const slimOptions = []
    data.edges.map(({ node }) => {
      return slimOptions.push({ label: node.treatmentTitle, value: node.slug })
    })
    return slimOptions
  }

  const defaultValue = [{ label: `Choose your ${category} treatment` }]
  const conditionDefaultValue = [{ label: `Choose your skin condition` }]

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={Arrow} alt="arrow down" />
      </components.DropdownIndicator>
    )
  }
  return (
    <TreatmentsSelectWrapper>
      <Container width="898px" noGutter>
        <Fade up delay={100}>
          <DropdownChecker>
            <DropdownControl>
              <Dropdown
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#d0eded",
                    primary: "#d0eded",
                    primary50: "#d0eded",
                  },
                })}
                isSearchable={true}
                options={options(treatmentsData)}
                defaultValue={
                  category === "conditions"
                    ? conditionDefaultValue[0]
                    : defaultValue[0]
                }
                id="treatments"
                onChange={({ value }) =>
                  navigate(
                    category === "aesthetic"
                      ? `/aesthetics/${value}/`
                      : `/${category}/${value}/`
                  )
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                }}
              />
            </DropdownControl>
          </DropdownChecker>
        </Fade>
      </Container>
    </TreatmentsSelectWrapper>
  )
}

// PricesAndOffers style props
TreatmentsSelect.propTypes = {
  sectionOptions: PropTypes.object,
}

// PricesAndOffers default style
TreatmentsSelect.defaultProps = {
  // section options default style
  sectionOptions: {
    mx: "auto",
    flexBox: true,
    justifyContent: "space-evenly",
    alignItems: "center",
    bg: "#fff",
    maxWidth: "353px",
    minHeight: "70px",
    borderRadius: "16px",
    boxShadow: "0 0 20px rgba(175, 182, 194, 0.3)",
  },
}

export default TreatmentsSelect
