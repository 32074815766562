import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 32px 0 0px;
  @media only screen and (max-width: 1440px) {
    padding: 32px 0 0px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 32px 0 0px;
  }
  @media (max-width: 990px) {
    padding: 32px 0 0px;
  }
  @media (max-width: 767px) {
    padding: 24px 0 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 24px 0 56px;
  }
  .image {
    width: ${props => (props.iconBigger ? "140px" : "70px")};
    margin: 0 auto 24px;
    transform: ${props => (props.imageRotate ? "rotate(-90deg)" : "none")};
    padding-bottom: ${props => (props.imageRotate ? "-40px" : "none")};
    @media only screen and (max-width: 480px) {
      width: 60px;
    }
  }
`

export const TreatmentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: 120px;
  @media only screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 34px;
    margin-top: 90px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 30px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 28px;
  }

  .treatments_post {
    position: relative;
    width: calc(100% / 3 - 50px);
    padding: 0px 32px 32px;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
    background-color: ${themeGet("colors.white", "#ffffff")};
    margin-bottom: 120px;
    text-align: center;
    @media only screen and (max-width: 1360px) {
      width: calc(100% / 3 - 30px);
      margin: 0 0 120px;
    }
    @media only screen and (max-width: 991px) {
      width: 100%;
      margin: 0 0 96px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 96px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      padding: 0px 16px 24px;
      margin-bottom: 80px;
    }
    &:last-child {
      @media only screen and (max-width: 480px) {
        margin-bottom: 0px;
      }
    }

    .read_more {
      color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
      font-size: 16px;
      font-weight: 300;
    }

    .thumbnail {
      width: 100px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);

      img {
        border-radius: 50%;
      }
    }

    .content {
      h3 {
        margin: 80px 0 16px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 300;
        letter-spacing: 1.28px;
        @media only screen and (max-width: 1440px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 1360px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media only screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 8px;
        }
      }

      p {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 28px;
        color: ${themeGet("colors.textColor", "#294859")};
        @media only screen and (max-width: 1360px) {
          font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
          line-height: 25px;
          /* color: #616970; */
        }
      }
    }
  }
`

export default SectionWrapper
