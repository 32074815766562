import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const TreatmentsSelectWrapper = styled.section`
  padding: 40px 0 0 0;
  z-index: 999;
  @media (max-width: 990px) {
    padding: 32px 0 0 0;
  }
  @media (max-width: 767px) {
    padding: 24px 0 0 0;
  }
`
export const DropdownChecker = styled.div`
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 90%;
  }
  .reusecore__input {
    width: 100%;
    color: red;
  }
  .field-wrapper input {
    color: red;
    min-height: 60px;
    padding: 12px 28px;
    box-shadow: 5px 10px #888888;
  }
  .reusecore__select {
    color: ${themeGet("colors.textColor", "#333333")};
    width: 353px;
  }
  .select__control {
    color: red;
    min-height: 72px;
    border-color: #fbfbf9;
    box-shadow: 0 0 20px rgba(175, 182, 194, 0.3) !important;
    padding: 0 20px 0 20px;
    border-radius: 16px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      min-height: 45px;
    }
    &:hover {
      border-color: #fbfbf9;
    }
  }
  .select__single-value {
    color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
    font-weight: 500;
  }
`

export const DropdownControl = styled.div`
  display: flex;
  align-items: center;
`

export default TreatmentsSelectWrapper
