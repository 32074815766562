import React from "react"
import { Modal } from "@redq/reuse-modal"
import { graphql } from "gatsby"

import ChangeYourLife from "../containers/ChangeYourLife"
import ConditionsSection from "../containers/Conditions"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import TreatmentsSelect from "../containers/TreatmentsSelect"
import { ContentWrapper } from "../containers/elegant.style"

const Conditions = ({ data, location }) => {
  const { contentfulConditions, allContentfulConditionsSkinCondition } = data
  const url = location.href ? location.href : ""

  return (
    <>
      <SEO
        url={url}
        title={contentfulConditions.seoDetails.metaTitle}
        shortDesc={contentfulConditions.seoDetails.metaShortDescription}
        description={contentfulConditions.seoDetails.metaDescription}
      />
      <Layout>
        <Modal />
        <ContentWrapper>
          <PageTitle
            title={contentfulConditions.title}
            subtitle={contentfulConditions.subtitle}
            text={contentfulConditions.text.text}
          />
          <TreatmentsSelect
            treatmentsData={allContentfulConditionsSkinCondition}
            category="conditions"
          />
          <ConditionsSection
            iconBigger
            conditionsData={allContentfulConditionsSkinCondition}
          />

          <ChangeYourLife
            title="Ready to start your journey with Elegant Health?"
            text="We’re here to help you feel your best. Explore our services, get in touch and book your healthcare consultation today."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default Conditions

export const conditionsQuery = graphql`
  query MyConditionsQuery {
    contentfulConditions {
      title
      subtitle
      text {
        text
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
    }
    allContentfulConditionsSkinCondition {
      edges {
        node {
          title
          slug
          thumbnail {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`
