import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import React from "react"

import Container from "../../components/UI/Container"
import TreatmentsPost from "../../components/TreatmentsPost"
import SectionWrapper, { TreatmentsWrapper } from "./conditions.style"

const Conditions = ({ iconBigger, conditionsData }) => {
  function truncate(str, no_words) {
    return `${str.split(" ").splice(0, no_words).join(" ")}...`
  }
  return (
    <SectionWrapper iconBigger={iconBigger}>
      <Container width="1340px">
        <TreatmentsWrapper>
          {conditionsData.edges.map((item, index) => {
            return (
              <TreatmentsPost
                key={index}
                slug={item.node.slug}
                category="conditions"
                thumbUrl={item.node.thumbnail.fluid}
                title={item.node.title}
                excerpt={
                  item.node.description.description.length < 160
                    ? item.node.description.description
                    : truncate(item.node.description.description, 25)
                }
              />
            )
          })}
        </TreatmentsWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Conditions
